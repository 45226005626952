import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import Button from '../../../parts/button/button'
import useActiveWebinars from 'store/navbar/useActiveWebinars'

import { popupName } from 'constants/auth/auth-data'
import { buttonIdNavbarLogin } from 'constants/clickableElementsId'
import { cutHashFromURL } from 'utils/getURLData'
import ButtonThemes from 'components/parts/button/themes'
import { PROMO_REDIRECT_URL } from 'constants/data'

import style from './navbar-right-side.module.scss'

interface INavbarNoAuth {
  congressTheme?: boolean
  studentsTheme?: boolean
  isNoAuth?: boolean
}

const NavbarNoAuth: FC<INavbarNoAuth> = ({ congressTheme, studentsTheme, isNoAuth }) => {
  const router = useRouter()
  const path = cutHashFromURL(router.asPath)
  const { isPageWithActiveWebinar } = useActiveWebinars()

  const loginHref = `${path}#${studentsTheme ? popupName.loginStudents : popupName.login}`
  const registrationHref = isPageWithActiveWebinar
    ? `${path}#${popupName.registrationWithSteps}`
    : `${path}#${studentsTheme ? popupName.registratiomStudentsOlymp : popupName.registration}`

  const regBtnTheme = congressTheme
    ? ButtonThemes.CONGRESS
    : studentsTheme
    ? ButtonThemes.STUDENTS_SECONDARY
    : undefined

  if (isNoAuth)
    return (
      <Link
        href={PROMO_REDIRECT_URL}
        className={style.buttonLogin}
        onClick={() => {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }}
      >
        На главную
      </Link>
    )

  return (
    <>
      {!studentsTheme && (
        <Link
          className={clsx(style.buttonLogin, {
            [style.congressLogin]: congressTheme,
          })}
          id={buttonIdNavbarLogin}
          href={loginHref}
        >
          Вход
        </Link>
      )}
      <div>
        {!studentsTheme && (
          <Link href={registrationHref} aria-label="Зарегистрироваться" prefetch={false}>
            <Button modificator={clsx(style.buttonReg)} theme={regBtnTheme}>
              Зарегистрироваться
            </Button>
          </Link>
        )}
      </div>
    </>
  )
}

export default NavbarNoAuth
